import React,{ useContext}  from 'react'
import styles from './Stat.module.scss'
import { MdDateRange } from 'react-icons/md'
import { IoIosHammer } from 'react-icons/io'
import { BsPeopleFill } from 'react-icons/bs'
import { InView } from 'react-intersection-observer';
import { useCountUp } from 'react-countup'
import {  GlobalDispatchContext,  GlobalStateContext } from "../context/GlobalContextProvider"
import PropTypes from 'prop-types';

const Stat = ({icon,text,number}) => {

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const { countUp, start } = useCountUp({
    duration: 3,
    end: number,
    startOnMount: false
  })

  const setShown = () => {
    dispatch({ type: "TOGGLE_SHOWN" })
  }

  const Icon = 
    icon === 'client' ? BsPeopleFill 
  : icon === 'year' ? MdDateRange 
  : icon === 'project' ? IoIosHammer
  : null

  const onChangeHandler = (inView) => {
    if(inView) {
      setTimeout(() => {
        setShown();
      }, 3000);
      start();
    }
  }

  return (
    <InView className={styles.statbox}
      triggerOnce
      as="div"
      onChange={(inView, entry) => onChangeHandler(inView)}
    >
      <Icon className={styles.icon}/>
      <div className={styles.number}>{!state.shown ? countUp : number}<span className={styles.plus}>+</span></div>
      <div className={styles.text}>{text}</div>
  </InView>
  )
}

export default Stat

Stat.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
}
