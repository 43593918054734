import React from "react"
import styles from "./Experience.module.scss"
import Stat from "../Stat"
import Button from "../Button"
import { graphql, useStaticQuery } from "gatsby"
import data from "../../data/sectionTexts"

const getLinks = graphql`
  query {
    allContentfulOFirme {
      oFirme: edges {
        node {
          kratkyPopis
          pocetProjektu
          pocetZakazniku
          pocetLetNaTrhu
        }
      }
    }
  }
`
const Experience = () => {
  const { allContentfulOFirme: { oFirme } } = useStaticQuery(getLinks)
  const { node: { 
    pocetProjektu, 
    pocetZakazniku, 
    pocetLetNaTrhu 
  }} = oFirme[0]
  const { title, button, desc } = data.experience;

  return (
    <section className={styles.experience}>
      <div className={styles.wrapper}>
        <h2>
          {title.thin}<span className="bold">{title.bold}</span>
        </h2>
        <div className={styles.text}>{desc}</div>
        <div className={styles.stats}>
          <Stat icon="client" number={pocetZakazniku} text="zákazníků" />
          <Stat icon="year" number={pocetLetNaTrhu} text="let na trhu" />
          <Stat icon="project" number={pocetProjektu} text="realizací" />
        </div>
        <Button link="/onas" color="blue">{button}</Button>
      </div>
    </section>
  )
}

export default Experience
